<template>
  <div class="page-content">
    <page-breadcrumb v-if="item" title="Career detail" class="mb-2" :items="breadcrumbItems" />
    <section class="mb-2">
      <b-card class="mt-1" v-if="item">
        <form-generator :model="item" :schema="item_form_schema" />
      </b-card>
    </section>

    <section class="mb-2" v-if="item">
      <h2 class="mb-2">Positions</h2>
      <data-table-ssr id="position_list" ref="position_list" :limit-base="36" :pagination="false"
        :columns="position_fields" :get-list-fn="getPositions"
        :create_schema="create_position_schema" :create-row-fn="createPosition" :item_default_data="{
          name: { type: 'translatable-text', translations: [] }
        }"
        :to-edit-fn="toPositionDetail"
        :delete-row-fn="deletePosition"
      />
    </section>

    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteItem"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from '../service';
import positionService from '../../position/service';

const item_form_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Key', field: 'key', disabled: true },
      { label: 'Name', field: 'name', input_type: 'ui-component' },
    ]
  },
];

const position_fields = [
  { label: 'Key', field: 'key' },
  { label: 'Career field', field: 'career_field' },
  { label: 'Name', field: 'name', display_type: 'translatable-text' },
];

const create_position_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Key', field: 'key', input_type: 'text', validate: { required: true }, },
      { label: 'Name', field: 'name', input_type: 'ui-component' },
    ]
  }
];

export default {
  data(){
    return{
      item_form_schema, position_fields, create_position_schema,
      item: null,
      isUpdating: false,
      isDeleting: false,
    }
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Career list',
          to: { name: 'ai_career-list' },
        },
        {
          text: `${this.item ? this.item.key : this.itemId}`, active: true
        },
      ]
      return items
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created(){
    this.getItem();
  },
  methods:{
    // item
    async getItem(){
      this.item = await service.get({id: this.itemId});
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.item),
      });
      this.getItem();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "ai_career-list" });
    },
    // position
    async createPosition(data) {
      let new_item = await positionService.create({ ...data, career_field: this.item.key });
      return new_item;
    },
    async getPositions({ limit, page, query }) {
      let data = await positionService.getList({
        query: JSON.stringify({ ...query, career_field: this.item.key }),
        limit, page
      });
      let list = [], total = 0;
      if (data) {
        list = data.list;
        total = data.total;
      }
      return { list, total };
    },
    async deletePosition({ _id }) {
      await positionService.delete({ id: _id });
    },
    toPositionDetail({ _id }) {
      this.$router.push({ name: 'ai_position-detail', params: { id: _id } });
    },
  }
}
</script>
